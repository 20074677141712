import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo"

const PrivacyPolicyPage = (props) => {
    let title = "Privacy Policy 🔒 | Sacramento Web Design - Pyxel Development"
    let location = props.location.pathname
    let description = "Pyxel Development prioritizes your privacy and the safety of your information. We take precautions to ensure that data-leaks never happen in any service that we provide, whether its web design, web development, web maintenance, seo, digital marketing, or eCommerce."
    return (
        <div>
            <Layout>
                <Seo title={title} location={location} description={description} />
                <div className="bg-offblue">
                    <div className="container mx-auto ">
                        <div className="flex justify-center lg:justify-end p-10 pt-36 xl:py-40">
                            <div>
                                <h1 className="text-6xl xl:text-7xl font-pixeboy text-white">
                                    PRIVACY POLICY
                                </h1>
                                <div className="mt-5 max-w-7xl mx-auto">
                                    <h2 className="text-3xl font-medium">Our Commitment To Privacy</h2>
                                    <h3 className="font-normal py-5"><i>Effective date:</i> November 8, 2021</h3>
                                    <p className="font-normal leading-8">Protecting your private information is our priority.</p>
                                    <p className="mt-4">This Statement of Privacy applies to https://pyxeldev.com and Pyxel Development, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to
                                        include https://pyxeldev.com and Pyxel Development.
                                    </p>
                                    <p className="mt-4">Pyxel Development is a Website Design, Website Development, Website Maintenance, Digital Marketing, SEO and Mobile App Agency site.</p>
                                    <p className="mt-4">By using the Pyxel Development website, you consent to the data practices described in this statement.</p>
                                    <p className="mt-4 font-bold">Collection of your Personal Information</p>
                                    <p className="mt-4">In order to better provide you with products and services offered on our Site,  Pyxel Development may collect personally identifiable information, such as your:</p>
                                    <ul className="mt-4 list-disc ml-9">
                                        <li>First and Last Name</li>
                                        <li>Mailing Address</li>
                                        <li>E-mail Address</li>
                                        <li>Phone Number</li>
                                        <li>Project Detail Information</li>
                                    </ul>
                                    <p className="mt-4">Pyxel Development may also collect anonymous demographic information, which is not unique to you, such as your:</p>
                                    <ul className="mt-4 list-disc ml-9">
                                        <li>Age</li>
                                        <li>Gender</li>
                                        <li>Race</li>
                                        <li>Religion</li>
                                        <li>Political Affiliation</li>
                                        <li>Household Income</li>
                                        <li>Utilizing Google Analytics to capture anonymous details</li>
                                    </ul>
                                    <p className="mt-4">Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Pyxel Developmen's public message boards, this information may be collected and used by others.</p>
                                    <p className="mt-4">We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or
                                        services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d)
                                        sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in
                                        relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
                                    <p className="mt-4 font-bold">Use of your Personal Information</p>
                                    <p className="mt-4">Pyxel Development collects and uses your personal information to operate its website(s) and deliver the services you have requested.</p>
                                    <p className="mt-4">Pyxel Development may also use your personally identifiable information to inform you of other products or services available from Pyxel Development and its affiliates.</p>
                                    <p className="mt-4 font-bold">Sharing Information with Third Parties</p>
                                    <p className="mt-4">Pyxel Development does not sell, rent or lease its customer lists to third parties. </p>
                                    <p className="mt-4">Pyxel Development may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or
                                        comply with legal process served on Pyxel Development or the site; (b) protect and defend the rights or property of Pyxel Development; and/or (c) act under exigent circumstances to protect the personal safety of users of
                                        Pyxel Development, or the public.</p>
                                    <p className="mt-4 font-bold">Tracking User Behavior</p>
                                    <p className="mt-4">Pyxel Development may keep track of the websites and pages our users visit within Pyxel Development, in order to determine what Pyxel Development services are the most popular.
                                        This data is used to deliver customized content and advertising within Pyxel Development to customers whose behavior indicates that they are interested in a particular subject area.</p>

                                    <p className="mt-4 font-bold">Automatically Collected Information</p>
                                    <p className="mt-4">Information about your computer hardware and software  may be automatically collected by Pyxel Development. This information can include: your IP address, browser type, domain names, access times and referring
                                        website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Pyxel Development website.</p>
                                    <p className="mt-4 font-bold">Use of Cookies</p>
                                    <p className="mt-4">The Pyxel Development website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer.
                                        Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
                                    <p className="mt-4">One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Pyxel Development pages,
                                        or register with Pyxel Development site or services, a cookie helps Pyxel Development to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on.
                                        When you return to the same Pyxel Development website, the information you previously provided can be retrieved, so you can easily use the Pyxel Development features that you customized.</p>
                                    <p className="mt-4">You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience
                                        the interactive features of the Pyxel Development services or websites you visit.
                                    </p>
                                    <p className="mt-4 font-bold">External Links</p>
                                    <p className="mt-4">This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to
                                        read the privacy statements of any other site that collects personally identifiable information.</p>
                                    <p className="mt-4 font-bold">Security of your Personal Information</p>
                                    <p className="mt-4">Pyxel Development secures your personal information from unauthorized access, use, or disclosure. Pyxel Development uses the following methods for this purpose:</p>
                                    <ul className="mt-4 list-disc ml-9">
                                        <li>SSL Protocol</li>
                                    </ul>
                                    <p className="mt-4">When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
                                    <p className="mt-4">We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can
                                        be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b)
                                        security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
                                    <p className="mt-4 font-bold">Children Under Thirteen</p>
                                    <p className="mt-4">Pyxel Development does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this
                                        website.</p>
                                    <p className="mt-4 font-bold">E-mail Communications</p>
                                    <p className="mt-4">From time to time, Pyxel Development may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to
                                        improve our Services, we may receive a notification when you open an email from Pyxel Development or click on a link therein.</p>
                                    <p className="mt-4">If you would like to stop receiving marketing or promotional communications via email from Pyxel Development, you may opt out of such communications by Clicking on the UNSUBSCRIBE button in our marketing newsletters.</p>
                                    <p className="mt-4 font-bold">External Data Storage Sites</p>
                                    <p className="mt-4">We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
                                    <p className="mt-4 font-bold">Changes to this Statement</p>
                                    <p className="mt-4">Pyxel Development reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email
                                        address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications
                                        will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
                                    <p className="mt-4 font-bold">Contact Information</p>
                                    <p className="mt-4">Pyxel Development welcomes your questions or comments regarding this Statement of Privacy. If you believe that Pyxel Development has not adhered to this Statement, please contact Pyxel Development at:</p>
                                    <p className="mt-4 font-bold">Company Address:</p>
                                    <ul className="mt-4 list-disc ml-9">
                                        <li>Pyxel Development</li>
                                        <li>500 Capitol Mall, Suite 2350</li>
                                        <li>Sacramento, CA 95814</li>
                                    </ul>
                                    <p className="mt-4 font-bold">Email Address:</p>
                                    <a href="mailto:admin@pyxeldev.com" title="Email Pyxel Development for Web Design or SEO" alt="Email Pyxel Development for Web Design or SEO" className="hover:text-gray-600"><p className="mt-4">admin@pyxeldev.com</p></a>
                                    <p className="mt-4 font-bold">Telephone Number:</p>
                                    <a href="tel:916-821-9044" title="Call Pyxel Development for Web Design or SEO" alt="Call Pyxel Development for Web Design or SEO" className="hover:text-gray-600"><p className="mt-4">(916) 821-9044</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default PrivacyPolicyPage